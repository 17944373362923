import { configureStore } from "@reduxjs/toolkit";
import { userSlice, searchSlice, sideCountsSlice } from "../Redux/UserSlice";


import { persistReducer } from 'redux-persist';

import thunk from 'redux-thunk';

import { combineReducers } from 'redux';

import storage from 'redux-persist/lib/storage';


const reducers = combineReducers({
  CMSAdminUser: userSlice.reducer,
  CMSAdminSideCounts: sideCountsSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});
